import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import MultiLingualContent from "./MultilingualContent/multilingualContent";
import AWS from "aws-sdk";
import client from "../client";
import api from "../api";
import { useLocation } from "react-router-dom";
import StripeCheckoutCard from "./Stripe/StripeCheckoutCard";

export default function HomeHeaderForm() {
  const [email, setEmail] = useState("");
  const [optIn, setOptIn] = useState(false);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const formHandler = (event) => {
    console.log(optIn);
    event.preventDefault();
    fetch("https://api.brevo.com/v3/contacts", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        //"api-key": "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",
        "api-key":
          "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",
        accept: "application/json",
      },
      body: JSON.stringify({
        listIds: [3],
        email: email,
        OPT_IN: optIn ? 1 : 0,
        email_address_check: undefined,
        locale: "en",
      }),
    });
    document.getElementById("sib-form").reset();
    // Toast.fire({
    //   icon: "success",
    //   title: "You are Subscribed!",
    // });
  };

  const region = "us-west-2";
  const accessKeyId = "AKIA4YOOEBDDQESOL3XZ";
  const secretAccessKey = "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";

  AWS.config.update({
    region: region,
    credentials: new AWS.Credentials(accessKeyId, secretAccessKey),
  });

  const s3 = new AWS.S3();

  //sets download counter
  const [downloads, setDownloads] = useState([]);
  useEffect(() => {
    const fetchDownlodads = async () => {
      const downloadResponse = await client.fetch(
        `*[_type == "downloads"]{
        downloads
      }`
      );
      const downloadData = await downloadResponse;
      setDownloads(downloadData);
    };
    fetchDownlodads();
  }, []);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const successParam = searchParams.get("success");
  const sessionIdParam = searchParams.get("session_id");

  const downloadArr = downloads;
  const countArr = [];

  downloadArr.forEach((item) => {
    countArr.push(item.downloads);
  });
  const finalCount = countArr[0];
  const incrementedCount = finalCount + 1;
  console.log(finalCount);

  //updates new download count
  const updateDownloads = async () => {
    // const countUpdate = await client
    //   .patch("ed4153f2-9f09-4e62-bfc4-0d33d5589272")
    //   .set({ downloads: incrementedCount })
    //   .commit();

    if (successParam) {
      const s3Url = s3.getSignedUrl("getObject", {
        Bucket: "yushatestzip",
        Key: sessionIdParam,
      });
      window.open(s3Url, "_blank");
    } else {
      const response = await fetch(api.createCheckoutSession, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const session = await response.json();
      window.open(session.session.url, "_blank");
    }
  };

  return (
    <div className="homeBrevoHeader">
      <div
        className="sib-form brevoForm "
        style={{ textAlign: "left", backgroundColor: "none" }}
      >
        <div id="sib-form-container " className="sib-form-container">
          <div
            id="error-message"
            className="sib-form-message-panel"
            style={{
              fontSize: 16,
              textAlign: "center",
              fontFamily: "&quot Helvetica&quot sans-serif",
              color: "#661d1d",
              backgroundColor: "#FFEDED",
              borderRadius: 3,
              borderColor: "#ff4949",
              maxWidth: 540,
            }}
          >
            <div className="sib-form-message-panel__text sib-form-message-panel__text--center">
              <svg
                viewBox="0 0 512 512"
                className="sib-icon sib-notification__icon"
              >
                <path d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-11.49 120h22.979c6.823 0 12.274 5.682 11.99 12.5l-7 168c-.268 6.428-5.556 11.5-11.99 11.5h-8.979c-6.433 0-11.722-5.073-11.99-11.5l-7-168c-.283-6.818 5.167-12.5 11.99-12.5zM256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28z" />
              </svg>
              <span className="sib-form-message-panel__inner-text">
                {" "}
                Your subscription could not be saved. Please try again.
              </span>
            </div>
          </div>
          <div></div>
          <div
            id="success-message"
            className="sib-form-message-panel"
            style={{
              fontSize: 16,
              textAlign: "center",
              fontFamily: "&quot Helvetica&quot sans-serif",
              color: "#085229",
              backgroundColor: "#e7faf0",
              borderRadius: 3,
              borderColor: "#13ce66",
              maxWidth: 540,
            }}
          >
            <div className="sib-form-message-panel__text sib-form-message-panel__text--center">
              <svg
                viewBox="0 0 512 512"
                className="sib-icon sib-notification__icon"
              >
                <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z" />
              </svg>
              <span className="sib-form-message-panel__inner-text">
                {" "}
                Thanks for signing up for more info about Yusha!
              </span>
            </div>
          </div>

          <div
            id="sib-container"
            className="sib-container--large sib-container--vertical grid"
            style={{
              textAlign: "center",
              backgroundColor: "transparent",
              maxWidth: 1200,
              borderRadius: 3,
              borderWidth: 0,
              borderColor: "none",
              borderStyle: "solid",
              direction: "ltr",
            }}
          >
            <form
              id="sib-form"
              method="POST"
              data-type="subscription"
              style={{ zIndex: "3", position: "relative" }}
              onSubmit={(event) => formHandler(event)}
            >
              <div style={{ padding: "8 0" }}>
                <div
                  className="sib-form-block"
                  style={{
                    fontSize: 30,
                    textAlign: "center",
                    fontWeight: 700,
                    fontFamily: "Zabal",
                    color: "#EE6611",
                    backgroundColor: "transparent",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 130,
                    zIndex: "3",
                  }}
                >
                  <h1>
                    <MultiLingualContent contentID="home_brevo_title" />
                  </h1>
                </div>
              </div>
              <div style={{ padding: "8 0" }}>
                <div
                  className="sib-form-block"
                  style={{
                    fontSize: 16,
                    textAlign: "left",
                    fontFamily: "Zabal",
                    color: "#3C4858",
                    backgroundColor: "transparent",
                  }}
                >
                  <div
                    className="sib-text-form-block"
                    style={{
                      fontSize: 30,
                      textAlign: "center",
                      fontWeight: 700,
                      fontFamily: "&quot Helvetica&quot sans-serif",
                      color: "#000000",
                      backgroundColor: "transparent",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      marginTop: 24,
                      maxWidth: 800,
                    }}
                  >
                    <h3>
                      <MultiLingualContent contentID="home_brevo_sub1" />
                    </h3>
                  </div>
                  {/* <div
                    className="sib-text-form-block"
                    style={{
                      fontSize: 30,
                      textAlign: "center",
                      fontWeight: 700,
                      fontFamily: "&quot Helvetica&quot sans-serif",
                      color: "#000000",
                      backgroundColor: "transparent",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      marginTop: 24,
                    }}
                  >
                    <h3>
                      <MultiLingualContent contentID="home_brevo_sub2" />
                    </h3>
                  </div>
                  <div
                    className="sib-text-form-block"
                    style={{
                      fontSize: 30,
                      textAlign: "center",
                      fontWeight: 300,
                      fontFamily: "&quot Helvetica&quot sans-serif",
                      color: "#000000",
                      backgroundColor: "transparent",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      marginTop: 24,
                    }}
                  ></div> */}

                  <div className="stripeCheckoutCardsContainer">
                    <StripeCheckoutCard name="Free" price="US$0" id="price_1OE92KKN0G6n0kh8jDOSl7Py" description="Dive in at no cost – perfect for exploring the basics." subscribeLabel="Start for free" /> 
                    <StripeCheckoutCard name="Individual" price="US$15" id="price_1OE92gKN0G6n0kh86aUNwAUj" description="Tailored for personal use, offering more than the basics." subscribeLabel="Get Started" /> 
                    <StripeCheckoutCard name="Business" price="US$50" id="price_1OE92xKN0G6n0kh8VnBK2Plu" description="Elevate teamwork and scale up with our business-focused subscription." subscribeLabel="Get Started" /> 
                  </div>

                  {/* <stripe-pricing-table
                    pricing-table-id="prctbl_1OE9CoKN0G6n0kh8LMfXnCOn"
                    publishable-key="pk_test_51MFGirKN0G6n0kh87HzFJiY9DL7x0WvKvj25DHsJRYY2a6OeAb3SYiWSnhrS2xc7ejPU29AgXwiivKN43srp5LQF00uZmyk1O4"
                  ></stripe-pricing-table> */}
                </div>
              </div>
              {/* <div style={{ padding: "8 0" }}>
                <div className="sib-input sib-form-block">
                  <div className="form__entry entry_block">
                    <div
                      className="form__label-row"
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        marginTop: 0,
                      }}
                    >
                      <div>
                        <div
                          className="btn cs-btn-software"
                          // href={s3.getSignedUrl("getObject", {
                          //   Bucket: "yushatestzip",
                          //   Key: "Yusha.zip",
                          // })}
                          onClick={updateDownloads}
                          style={{
                            color: "#FFFFFF",
                            textDecoration: "none",
                            paddingTop: 12,
                            paddingBottom: 12,
                          }}
                        >
                          {" "}
                          <img
                            src={"images/AppleLogo.png"}
                            className="homeHeaderAppleLogo"
                            alt="Apple Logo"
                          />{" "}
                          <MultiLingualContent contentID="home_brevo_submit" />
                        </div>
                      </div>
                    </div>
                    <label
                      className="entry__error entry__error--primary"
                      style={{
                        fontSize: 16,
                        textAlign: "left",
                        fontFamily: "&quot Helvetica&quot sans-serif",
                        color: "#661d1d",
                        backgroundColor: "#ffeded",
                        borderRadius: 3,
                        borderColor: "#ff4949",
                      }}
                    ></label>
                  </div>
                </div>
              </div> */}
              {/* <div
                className="sib-form-block"
                style={{
                  fontSize: 30,
                  textAlign: "center",
                  fontWeight: 700,
                  fontFamily: "Zabal",
                  color: "#000",
                  backgroundColor: "transparent",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 24,
                  zIndex: "3",
                }}
              >
                <p>
                  <MultiLingualContent contentID="home_brevo_info" />
                </p>
              </div> */}

              {/*<div style={{padding: "8px 0"}}>*/}
              {/*    <div className="sib-optin sib-form-block">*/}
              {/*        <div className="form__entry entry_mcq">*/}
              {/*            <div className="form__label-row ">*/}
              {/*                <div className="entry__choice" style={{}}>*/}
              {/*                    <label>*/}
              {/*                        <input type="checkbox" className="input_replaced" value="1" id="OPT_IN"*/}
              {/*                               name="OPT_IN" onClick={() => setOptIn((prev) => !prev)}/>*/}
              {/*                        <span className="checkbox checkbox_tick_positive"*/}
              {/*                              style={{marginLeft: 0}}>*/}
              {/*                        </span>*/}
              {/*                        <span style={{*/}
              {/*                            fontSize: 14,*/}
              {/*                            textAlign: "left",*/}
              {/*                            fontFamily: "&quot Helvetica&quot sans-serif",*/}
              {/*                            color: "#3C4858",*/}
              {/*                            backgroundColor: "transparent"*/}
              {/*                        }}>*/}
              {/*                            <p>I agree to receive your newsletters and accept the data privacy statement.*/}
              {/*                            </p>*/}
              {/*                        </span>*/}
              {/*                    </label>*/}
              {/*                </div>*/}
              {/*            </div>*/}
              {/*            <label className="entry__error entry__error--primary" style={{*/}
              {/*                fontSize: 16,*/}
              {/*                textAlign: "left",*/}
              {/*                fontFamily: "&quot Helvetica&quot sans-serif",*/}
              {/*                color: "#661d1d",*/}
              {/*                backgroundColor: "#ffeded",*/}
              {/*                borderRadius: 3,*/}
              {/*                borderColor: "#ff4949"*/}
              {/*            }}>*/}
              {/*            </label>*/}
              {/*            <label className="entry__specification" style={{*/}
              {/*                fontSize: 12,*/}
              {/*                textAlign: "left",*/}
              {/*                fontFamily: "&quot Helvetica&quot  sans-serif",*/}
              {/*                color: "#8390A4",*/}
              {/*            }}> You may unsubscribe at any time using the link in our newsletter.*/}
              {/*            </label>*/}
              {/*        </div>*/}
              {/*    </div>*/}
              {/*</div>*/}

              <input
                type="text"
                name="email_address_check"
                value=""
                className="input--hidden"
              />
              <input type="hidden" name="locale" value="en" />
            </form>
          </div>
        </div>
      </div>
      <div className="homeHeaderImgGroup">
        <img
          src="images/yushaRaisedBubble.png"
          className="homeHeadImg1"
          alt=""
        />
        <img src="images/yushaDancing.png" className="homeHeadImg2" alt="" />
        <img src="images/yushaVictory.png" className="homeHeadImg3" alt="" />
      </div>
    </div>
  );
}
