import React, { useEffect, useState } from "react";
import "./style.css";
import api from "../../api";
import Stripe from "stripe";

export default function StripeCheckoutCard({ id, price, name, description, subscribeLabel }) {
//   const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(false);
  const stripe = Stripe(process.env.REACT_APP_STRIPE_SECRET_KEY);

  const handleCheckout = async () => {
    try{
     let session = await stripe.checkout.sessions.create({
        payment_method_types: ["card"],
        mode: "subscription",
        success_url: `https://yusha.ai/software?success=true&session_id={CHECKOUT_SESSION_ID}}`,
        cancel_url: `https://yusha.ai/?canceled=true`,
        line_items: [
          {
            price: id,
            quantity: 1,
          },
        ],
      })

      console.log(session);
      window.open(session.url, "_blank");
    }catch(err){
      console.log({ error: "Failed to create checkout session", err });
    }
    
  };

  // const handleCheckout = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await fetch(api.createCheckoutSession, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ priceid: id }),
  //     });
  //   //   setSession(data.session);

  //     const session = await response.json();
  //     window.open(session.session.url, "_blank");

  //   } catch (err) {
  //     console.log(err);
  //   }
  //   setLoading(false);
  // };

  return (
    <div className="pricebox-container">
      <h4 className="price-title">{name}</h4>
      <div className="price-container">
        <div className="price-value">{price}</div>
        <span>per<br/>month</span>
      </div>
      <button
        className="btn btn-lg btn-block subscribeButton"
        onClick={handleCheckout}
        disabled={loading}
      >
        {loading ? "Loading..." : subscribeLabel}
      </button>
      <p className="price-description">{description}</p>
    </div>
  );
}
